<template>
  <div>
    <!-- Header -->
    <div class="header py-7 py-lg-8 pt-lg-9" style="background-color: white">
      <b-container>
        <div class="header-body text-center mb-6">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-black" style="color: #9b5151">
                Welcome Engineer!
              </h1>
              <p class="text-lead text-black" style="color: #e97878">
                มาตะลุยข้อสอบ กว เต็มรูปแบบครบทุกข้อ <br />ครบทุกวิชา
                ให้เตรียมสอบกันอย่างจุใจ
              </p>
              <b-button
                v-if="$store.state.username"
                variant="outline-primary"
                class="text-left mb-1"
                @click="$store.commit('NEXT_PAGE', { page: 'โปรไฟล์' })"
                >โปรไฟล์</b-button
              >
              <b-button
                v-else
                variant="outline-primary"
                class="text-left mb-3"
                @click="$store.commit('NEXT_PAGE', { page: 'ทดสอบ' })"
                >ทดลองใช้งาน</b-button
              >
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col>
              <div
                class="gallery-container gallery-garments hide"
                style="height: 200px"
              >
                <div
                  class="gallery-item"
                  v-for="(image, i) in subject_cover"
                  :key="i"
                >
                  <img
                    height="200"
                    width="200"
                    class="image"
                    :src="image"
                    @click="onClick(i)"
                  />
                </div>
                <vue-gallery-slideshow
                  :images="subject_cover"
                  :index="index"
                  @close="index = null"
                ></vue-gallery-slideshow>
              </div>
            </b-col>
          </b-row> -->
        </div>
      </b-container>

      <modal :show.sync="modals.notice">
        <div>
          <b-carousel
            id="carousel"
            controls
            indicators
            img-width="1024"
            img-height="480"
          >
            <!-- <b-carousel-slide :img-src="pic0"></b-carousel-slide> -->
            <b-carousel-slide :img-src="pic1"></b-carousel-slide>
            <b-carousel-slide :img-src="pic2"></b-carousel-slide>
            <b-carousel-slide :img-src="pic3"></b-carousel-slide>
            <b-carousel-slide :img-src="pic4"></b-carousel-slide>
            <b-carousel-slide :img-src="pic5"></b-carousel-slide>
            <b-carousel-slide :img-src="pic6"></b-carousel-slide>
            <b-carousel-slide :img-src="pic7"></b-carousel-slide>
          </b-carousel>
          <iframe
            v-if="$store.state.have_ads"
            data-aa="1709278"
            src="//ad.a-ads.com/1709278?size=468x60"
            scrolling="no"
            style="
              width: 468px;
              height: 60px;
              border: 0px;
              padding: 0;
              overflow: hidden;
            "
            allowtransparency="true"
          ></iframe>
        </div>

        <template slot="footer">
          <base-button type="white" @click="modals.notice = false"
            >เริ่มต้นใช้งาน</base-button
          >
          <!-- <base-button
          type="link"
          class="text-white ml-auto"
          @click="modals.notice = false"
          >Close</base-button
        > -->
        </template>
      </modal>

      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <!-- <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon> -->
          <polygon
            points="2560 0 2560 100 0 100"
            style="fill: #8f384d; stroke: #8f384d; stroke-width: 1"
          />
        </svg>
      </div>
    </div>
    <!-- Page content -->

    <b-container class="mt--9 pb-5" v-if="$store.state.username == ''">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary">
            <!-- <b-card-header class="bg-transparent pb-5"  >
              <div class="text-muted text-center mt-2 mb-3"><small>Sign in with</small></div>
              <div class="btn-wrapper text-center">
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="img/icons/common/github.svg"></span>
                  <span class="btn-inner--text">Github</span>
                </a>
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="img/icons/common/google.svg"></span>
                  <span class="btn-inner--text">Google</span>
                </a>
              </div>
            </b-card-header> -->
            <b-card-body class="px-lg-5 py-lg-5">
              <!-- <div class="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div> -->
              <validation-observer
                v-slot="{ handleSubmit }"
                ref="formValidator"
              >
                <b-form
                  role="form"
                  @submit.prevent="handleSubmit(onSubmit)"
                  v-focusNextOnEnter
                >
                  <base-input
                    alternative
                    class="mb-3"
                    name="email"
                    :rules="{ required: true, email: true }"
                    prepend-icon="ni ni-email-83"
                    placeholder="ชื่อผู้ใช้"
                    v-model="model.username"
                  >
                  </base-input>

                  <!-- <base-input
                    alternative
                    class="mb-3"
                    name="Password"
                    :rules="{ required: true, min: 6 }"
                    prepend-icon="ni ni-lock-circle-open"
                    :type="showPassword ? 'text' : 'password'"
                    placeholder="รหัสผ่าน"
                    v-model="model.password"
                  >
                  </base-input> -->

                  <b-input-group class="mb-3">
                    <b-input-group-prepend is-text>
                      <i class="ni ni-lock-circle-open"></i>
                    </b-input-group-prepend>
                    <b-form-input
                      alternative
                      name="Password"
                      :rules="{ required: true, min: 6 }"
                      prepend-icon="ni ni-lock-circle-open"
                      :type="showPassword ? 'text' : 'password'"
                      placeholder="รหัสผ่าน"
                      v-model="model.password"
                    ></b-form-input>
                    <b-input-group-append is-text
                      ><b-icon
                        :icon="showPassword ? 'eye-fill' : 'eye-slash-fill'"
                        aria-hidden="true"
                        @click="showPassword = !showPassword"
                      ></b-icon
                    ></b-input-group-append>
                  </b-input-group>

                  <!-- <b-form-checkbox v-model="showPassword"
                    >ดูหรัสผ่าน</b-form-checkbox
                  > -->
                  <div class="text-center">
                    <base-button
                      native-type="submit"
                      class="mt-2"
                      style="background-color: #e97878"
                      >เข้าสู่ระบบ</base-button
                    >
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
          <b-row class="mt-3">
            <b-col cols="6">
              <router-link to="/forgotpassword" class="text-light"
                ><small style="color: #f5d782">ลืมรหัสผ่าน</small></router-link
              >
            </b-col>
            <b-col cols="6" class="text-right">
              <router-link to="/register" class="text-light"
                ><small style="color: #f5d782"
                  >สมัครสมาชิกใหม่</small
                ></router-link
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- <b-row>
        <b-col>
          <div class="gallery-container gallery-garments hide" style="height:400px">
            <div class="gallery-item" v-for="item in subject_cover" :key="item">
              <img height="400" width="400" :src="item" />
            </div>
          </div>
        </b-col>
      </b-row> -->
    </b-container>
  </div>
</template>
<script>
// import AdsLogin from "./AdsLogin.vue";
// import VueGallerySlideshow from "vue-gallery-slideshow";
export default {
  // components: { VueGallerySlideshow },
  name: "Login",
  data() {
    return {
      showPassword: false,
      index: -1,
      modals: { notice: false },
      model: {
        username: "",
        password: "",
      },
      // pic0: require("@/assets/0.webp"),
      pic1: require("@/assets/1.webp"),
      pic2: require("@/assets/2.webp"),
      pic3: require("@/assets/3.webp"),
      pic4: require("@/assets/4.webp"),
      pic5: require("@/assets/5.webp"),
      pic6: require("@/assets/6.webp"),
      pic7: require("@/assets/7.webp"),
      subject_cover: [
        require("@/assets/s1.webp"),
        require("@/assets/s2.webp"),
        require("@/assets/s3.webp"),
        require("@/assets/s4.webp"),
        require("@/assets/s5.webp"),
        require("@/assets/s6.webp"),
        require("@/assets/s7.webp"),
        require("@/assets/s8.webp"),
        require("@/assets/s9.webp"),
        require("@/assets/s10.webp"),
      ],
    };
  },
  methods: {
    // nextPage(page) {
    //   this.$router.push({ name: page });
    // },
    onClick(i) {
      this.index = i;
    },
    onSubmit() {
      //   var e = this.$parent.$parent.$parent;
      //   e.login(this.model);
      this.model.username = this.model.username.toLowerCase();
      this.$store.commit("LOGIN", this.model);
      //   this.$store.dispatch("asyncLogin", this.model);
      //   this.$refs.toast1.show();
    },
  },
  mounted() {
    this.modals.notice = true;
    this.$bvToast.show("example-toast");
  },
  directives: {
    focusNextOnEnter: {
      inserted(el, binding, vnode) {
        el.addEventListener("keyup", (ev) => {
          if (ev.keyCode === 13) {
            // console.log([...vnode.elm.elements].indexOf(ev.target));
            for (
              let i = [...vnode.elm.elements].indexOf(ev.target) + 1;
              i < vnode.elm.length;
              i++
            ) {
              //   console.log(vnode.elm[i].tagName);
              if (
                vnode.elm[i].tagName == "INPUT" ||
                vnode.elm[i].tagName == "BUTTON"
              ) {
                vnode.elm[i].focus();
                break;
              }
            }
          }
        });
      },
    },
  },
};
</script>
<style scoped>
.gallery-item {
  display: inline-block;
}

.gallery-container {
  display: flex;
  flex-direction: row;
  height: 200px;
  overflow-x: scroll;
}
</style>